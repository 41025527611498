import React from "react";
import "./style.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <hr />
        <div className="bottom">
          <p> @ all rights are save by software Engineering Future </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
